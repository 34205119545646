<template>
    <div class="goods">
      <div class="active">
        <div>{{num}} + {{ demo }}</div>
        <!-- <button class="login-button" @click="openapp">唤起应用</button> -->
      </div>
    </div>



  </template>
  
  <script>
        // let isHidden = false;
        // const message = $message();
        import wx from 'weixin-js-sdk'
        import sha1 from 'js-sha1' //mian.js
  export default {
    data() {
      return {
        demo:null,
        num:0,
        time:'',
        nums: '',
        signature:'',
        appId: ''
      };
    },
    created() {
    },
    mounted() {
        var chars = ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];  
				var nums="";  
				for(var i=0;i<32;i++){  
					var id = parseInt(Math.random()*35);  
					nums+=chars[id];  
				}  
				this.nums = nums;
                let dataBeforeSign = {
							 "appid": "wxa9a9e7a161a0e989",
							  "body": "h",
							  "mch_id": "15034220121",
							  "nonce_str": this.nums,
							  "notify_url": "http://101.132.238.216:8000/api/callBack/wx_pay_callBack",
							  "out_trade_no": "202005209809",
							  "spbill_create_ip": "101.132.238.216",
							  "total_fee": "101",
							  "trade_type": "MWEB"
							}
				let arr = [];
				for (var key in dataBeforeSign) {
					arr.push(key)
				}
				arr.sort();
				console.log(arr)
				let str = '';
				for (var i in arr) {
					str += arr[i] + "=" + dataBeforeSign[arr[i]] + "&"
				}
				let stringA = str.substr(0, str.length - 1)
				let stringSignTemp=stringA+"&key=ec919148489a367ddabce94b042f7031"
				this.sign = md5(stringSignTemp).toUpperCase();

            let sha1 = require('js-sha1');//vue
            this.signature = sha1(stringA)
            
            //得到日期随机数 timestamp
            let timestamp = Math.round(new Date() / 1000)
            this.time = timestamp.toString();
            this.appid = 'wx0ff59ec3274e0a53';
			let url = window.location.href;
			console.log(url)
			url = url.split('#')[0]
			console.log(url)
		
            wx.config({
                debug: true,
                appId: that.appid,  // 此处APPID为公众号的APPID
                timestamp: that.time, 
                nonceStr: that.nums, 
                signature: that.signature,
                jsApiList: ['onMenuShareTimeline'], //此处必须填写一个，不为null就行
                openTagList: ['wx-open-launch-app'] 
            })
            wx.ready(function () {
                console.log('ready')
                wx.checkJsApi({
                    jsApiList: ['wx-open-launch-app'], // 校验跳转APP的标签是否可用
                    success: function (res) {
                    console.log('可用')
                    console.log('config111')
                    },
                    fail: (err) => {
                    console.log(err, '不可用')
                    }
                })
            }) 
            wx.error(function(res){
                console.log("error：",res)
            });
    },
    methods: {

  
        // openapp() {
        //     this.num++;




        //     var url = 'yourapp://11223366'; // 替换为你的自定义scheme
        //     window.location.href = url;      
        // },
      
    },
  };
  </script>
  
  <style scoped>
  .goods {
    text-align: center;
    min-height: 100vh;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-pack: center;
    background: #0a045b;
    position: relative;
  }
  .login-button {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    border: none;
    font-weight: bold;
    color: white;
    background: #1d2473;
  }
  
  .active {
    margin-top: 15px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.9);
    width: 80vw;
    margin-left: 10vw;
    border-radius: 10px;
    padding: 20px 20px 30px;
    box-sizing: border-box;
  }




  * {
    margin: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
}

body {
    width: 100%;
    height: 100vh;
}

.content {
    width: 100%;
    height: calc(100vh - 150px);
    background: linear-gradient(#008B8A, #fff, #fff) no-repeat;
    overflow-x: hidden;
    overflow-y: scroll;
}

.header {
    width: 100%;
    text-align: center;
}

.header img {
    margin-top: 80px;
    width: 380px;
    height: 164px;
}

.header .tips {
    color: #fff;
    margin-top: 20px;
    font-size: 40px;
}

.button {
    text-align: center;
    position: fixed;
    bottom: 20px;
    margin-left: 2.5vw;
}

button {
    width: 95vw;
    color: #fff;
    height: 120px;
    font-size: 50px;
    border-radius: 15px;
}

.title {
    width: 95vw;
    height: 150px;
    border-radius: 15px;
    background-color: #fff;
    margin: 60px 2.5vw;
    display: flex;
    line-height: 150px;
    box-shadow: 0 0 2px 1px #dbdbdb;
}

.title img {
    width: 120px;
    height: 120px;
    margin-top: 15px;
    margin-left: 30px;
}

.title .desc {
    margin-left: 30px;
    font-size: 40px;
    color: rgb(0, 138, 139);
    font-weight: bold;
}

.card {
    position: relative;
    font-weight: bold;
    font-size: 40px;
    width: 95vw;
    margin-top: 40px;
    margin-left: 2.5vw;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 0 2px 1px #dbdbdb;
    margin-bottom: 30px;
}

.time {
    margin-left: 60px;
    height: 150px;
    line-height: 150px;
}

.time span:nth-child(2) {
    margin-left: 40px;
}

.card-item {
    width: 85vw;
    height: 220px;
    margin-left: 60px;
    display: flex;
}

.circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgb(217, 223, 232);
    margin-top: 15px;
}

.line {
    position: absolute;
    width: 10px;
    height: 195px;
    background-color: rgb(217, 223, 232);
    margin-left: 10px;
}

.price {
    text-align: right;
    margin-right: 80px;
    margin-bottom: 40px;
    font-size: 46px;
    font-weight: normal;
}

.cus-button {
    width: 90vw;
    height: 120px;
    line-height: 120px;
    text-align: center;
    color: #fff;
    margin-left: 2.5vw;
    margin-bottom: 40px;
    background-color: rgb(0, 138, 139);
    border-radius: 15px;
    font-weight: normal;
}
#alert {
    display: none;
    position: relative;
    background: rgba(0, 0, 0, .5);
    width: 100vw;
    height: 100vh;
    z-index: 100;
}
.alert {
    position: absolute;
    width: 95vw;
    height: 580px;
    left: 2.5vw;
    top: 50%;
    margin-top: -300px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 0 8px 4px #dbdbdb;
}
.alert .tips {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    height: 100px;
    line-height: 100px;
    border-bottom: 1px solid #9e9e9e;
}
.alert .cont {
    font-size: 50px;
    text-align: center;
    color: #8a8a8a;
    height: 150px;
    margin-top: 100px;
    margin-bottom: 100px;
}
.alert .btn {
    border-top: 1px solid #9e9e9e;
    font-size: 40px;
    display: flex;
    text-align: center;
    height: 128px;
}
.alert .btn div {
    width: 50vw;
    line-height: 128px;
}
.alert .btn div:nth-child(1) {
    border-right: 1px solid #9e9e9e;
    color: #8a8a8a;
}
.alert .btn div:nth-child(2) {
    color: rgb(0, 138, 139);
}

  </style>
  
  